<template>
    <div class="banner-confirm-email animate__animated animate__pulse">
        <p>{{$t('profile.confirmEmail.title')}}</p>
        <button :class="['cta cta-slim cta-neutral', { loading: loading }]" @click="resend_confirmation_link()">{{$t('profile.confirmEmail.resend')}}</button>
    </div>
</template>

<script>

export default {
    name: "ConfirmEmailBanner",

    data() {
        return {
            loading: false,
        }
    },

    methods: {
        resend_confirmation_link: function() {
            const self = this;
            
            self.loading = true;
            self.$axios
                .post('/signup/validate/reset')
                .then(response => {
                    if(response.status == 200) {
                        self.loading = false;
                        self.$toasted.success(
                            self.$t('notifications.emailConfirmationLinkResendSuccess'),
                            { icon: 'circle-check' }
                        );
                    }
                })
                .catch(err => { 
                    self.loading = false;
                    console.log(err) 
                });
        }
    },
};
</script>