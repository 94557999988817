<template>
    <div v-if="modal" class="modal payment-method-needed" :class="{ active: modal }">
        <div class="modal-card bottom">
            <div class="text-with-icon">
                <font-awesome-icon class="warning" :icon="['fa', 'triangle-exclamation']" />
                <p class="title" v-html="$t(`modal.payment-method-needed.title`)"></p>
            </div>

            <div class="modal-body">
                <p v-html="$t(`modal.payment-method-needed.body`)"></p>
            </div>

            <div class="cta-container">
                <button class="cta cta-outline dark cta-slim" @click="close()">{{$t('payments.modal.after_add_payment_method.close')}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'PaymentMethodNeededModal',

    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        show: function(val) {
            const self = this;
            self.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { 
                self.$utils._open_modal(); 
            }
            else { self.$utils._close_modal(); }
        }
    },
    
    data() {
        return {
            modal: false,
        }
    },
    
    methods: {
        ...mapActions([
            'set_payment_method_needed_modal_state',
        ]),

        close: function() {
            const self = this;

            self.modal = false;
            self.set_payment_method_needed_modal_state(false);
        }
    }
}
</script>