<template>
    <div v-if="data && (data.error || data.warning)" id="form-error-container" :class="classes">
        <p :id="computed_id" :class="['form-error']"><font-awesome-icon :icon="['fa', 'circle-exclamation']" :class="[{warning: data.warning}]" />{{$t(data.key)}}</p>
    </div>
</template>

<script>

export default {
    name: "FormError",
    props: {
        data: {
            type: Object,
            default: null
        },

        classes: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    
    computed: {
        computed_id: function() {
            return this.data.name ? `form-error_${this.data.name}` : ''
        }
    }
};
</script>