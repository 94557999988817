<template>
    <div v-if="show_install_on_mobile_modal" :class="['modal install-on-mobile', { active: show_install_on_mobile_modal, tablet: meta.is_tablet, mobile: meta.is_mobile }]">
        <div class="modal-card">
            <div class="modal-title with-icon with-icon-compact">
                <p class="title">{{$t('navigation.install.modal.title', { device: meta.is_mobile ? $t('navigation.install.devices.mobile') : $t('navigation.install.devices.tablet') })}}</p>
                <font-awesome-icon @click="close_modal()" :icon="['fa', 'square-xmark']" />
            </div>

            <div v-if="meta.is_android">
                <div class="modal-body">
                    <div class="steps">
                        <div class="step-1">
                            <p class="title" v-html="$t('navigation.install.modal.steps.android.step-1')"></p>
                            <img src="../../assets/images/install_step_1_android.jpg" alt="step-1-instruction-image">
                        </div>

                        <div class="step-2">
                            <p class="title" v-html="$t('navigation.install.modal.steps.android.step-2')"></p>
                            <img src="../../assets/images/install_step_2_android.jpg" alt="step-2-instruction-image">
                        </div>

                        <div class="step-3">
                            <p class="title" v-html="$t('navigation.install.modal.steps.android.step-3')"></p>
                            <img src="../../assets/images/install_step_3_android.jpg" alt="step-3-instruction-image">
                        </div>

                        <div class="step-4">
                            <p class="title" v-html="$t('navigation.install.modal.steps.android.step-4', { device: meta.is_mobile ? $t('navigation.install.devices.mobile') : $t('navigation.install.devices.tablet') })"></p>
                            <img src="../../assets/images/install_step_4_android.jpg" alt="step-4-instruction-image">
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-else>
                <div class="modal-body" v-if="show_safari">
                    <div class="steps">
                        <div class="step-1">
                            <p class="title" v-html="$t('navigation.install.modal.steps.ios.step-1')"></p>
                            <img src="../../assets/images/install_step_1.jpg" alt="step-1-instruction-image">
                        </div>

                        <div class="step-2">
                            <p class="title" v-html="$t('navigation.install.modal.steps.ios.step-2')"></p>
                            <img src="../../assets/images/install_step_2.png" alt="step-2-instruction-image">
                        </div>

                        <div class="step-3">
                            <p class="title" v-html="$t('navigation.install.modal.steps.ios.step-3')"></p>
                            <img src="../../assets/images/install_step_3.jpg" alt="step-3-instruction-image">
                        </div>

                        <div class="step-4">
                            <p class="title" v-html="$t('navigation.install.modal.steps.ios.step-4', { device: meta.is_mobile ? $t('navigation.install.devices.mobile') : $t('navigation.install.devices.tablet') })"></p>
                            <img src="../../assets/images/install_step_4.jpg" alt="step-4-instruction-image">
                        </div>
                    </div>
                </div>

                <div class="modal-body empty" v-else>
                    <p class="not-safari" v-html="$t('navigation.install.modal.not-safari', { url: vue_app_url_short , device: meta.is_mobile ? $t('navigation.install.devices.mobile') : $t('navigation.install.devices.tablet') })"></p>
                    <copy-to-clipboard :text="vue_app_url" @copy="copy_to_clipboard('link')">
                        <button class="cta cta-outline dark cta-slim">{{ $t('navigation.install.modal.copy-url') }}</button>
                    </copy-to-clipboard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CopyToClipboard from 'vue-copy-to-clipboard';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'InstallOnMobileModal',

    components: {
        CopyToClipboard
    },

    props: {
        meta: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    watch: {
        show_install_on_mobile_modal: function() { 
            const self = this;
            if(self.show_install_on_mobile_modal) { 
                self.$utils._open_modal(); 
            }
            else { self.$utils._close_modal(); }
        }
    },

    computed: {
        ...mapGetters([
            'show_install_on_mobile_modal'
        ]),

        show_safari: function() {
            return this.meta.is_safari;
        },

        vue_app_url_short: function() {
            return process.env.VUE_APP_URL.slice(8, process.env.VUE_APP_URL.length)
        },

        vue_app_url: function() {
            return process.env.VUE_APP_URL
        }
    },
    
    methods: {
        ...mapActions([
            'set_show_install_on_mobile_modal'
        ]),

        close_modal: function() {
            const self = this;

            self.$utils._close_modal();
            self.set_show_install_on_mobile_modal(false);
        },

        copy_to_clipboard: function(type) {
            const self = this;
            self.$toasted.success(
                self.$t(`notifications.referral_${type}_copied`),
                { icon: 'circle-check', position: 'top-right' }
            );
        },
    }
}
</script>