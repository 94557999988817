<template>
    <div>
        <div v-if="in_modal" :class="classes">
            <slot></slot>
        </div>
        <div v-else :class="['outer-wrapper', scope, meta ]">
            <simple-navigation v-if="show_simple_navigation()" :routeName="routeName" :container="container"></simple-navigation>
            <main-navigation v-else-if="$screen.width > 768" :routeName="routeName" :isDashboard="is_dashboard_layout"></main-navigation>

            <div id="pace" class="pace"></div>

            <div class="inner-wrapper">
                <main role="main" class="main" :class="[classes]">
                    <side-bar v-if="is_dashboard_layout" />
                    
                    <div :class="['main-container', { container }]">
                        <slot></slot>
                        <Footer v-if="is_dashboard_layout" />   
                        
                        <ReferralBanner v-if="!show_confirm_email_banner" />  
                        <ConfirmEmailBanner v-if="show_confirm_email_banner" />
                    </div>
                </main>
            </div>
            

            <MobileNavigation :routeName="routeName" />
            <WorkerReferralModal :show="referral_modal_state" />
            <SupportModal :show="support_modal_state" />
            <StatsDoNotMatchModal :show="stats_do_not_match_modal_state" />
            <InstallOnMobileModal :meta="meta" />
            <PaymentMethodNeededModal :show="payment_method_needed_modal_state" />
            
            <div v-if="show_reload_for_new_version" class="modal new-version" :class="{ active: show_reload_for_new_version }">
                <div class="modal-card">
                    <div class="modal-title">
                        <p class="title">{{$t('navigation.new-version.modal.title')}}</p>
                    </div>

                    <div class="modal-body">
                        <button class="cta cta-primary cta-slim" @click="reload()">{{$t('navigation.new-version.modal.button')}}</button>
                    </div>
                </div>
            </div>
            
            <div v-if="auth" class="auth-footer">
                <div class="content">
                    <div class="lang-help-container">
                        <div class="language" @click="change_current_language(currentLanguage)">
                            <font-awesome-icon v-if="$screen.width > 768" :icon="['fa', 'earth-americas']" />
                            <span class="label">{{ $t(`navigation.mobile.labels.lang.${not_current_language}`) }}</span>
                        </div>
                        <p v-if="$screen.width <= 768" class="help hover-pointer" @click="set_support_modal_state(true)">Aide & support</p>
                    </div>
                    

                    <div class="help-status-container" v-if="$screen.width > 768">
                        <a class="status" href="https://stats.uptimerobot.com/pRwAvs9Bvk" target="_blank">
                            <span class="dot"></span>
                            <p>{{ $t('login.status') }}</p>
                        </a>

                        <p class="help hover-pointer" @click="set_support_modal_state(true)">Aide & support</p>
                    </div>
                    
                    <img v-else class="auth-footer-logo hover-pointer" @click="$utils._navigate_to_name('dashboard')" src="../assets/images/likab_icon_circle.png" alt="likab logo icon">
                </div>
            </div>
            
            <div class="not-availble-on-mobile">
                <div class="card">
                    <img src="../assets/images/likab_logo_min.png" alt="LikaB logo" title="LikaB logo" loading="eager">
                    <p>{{$t('navigation.not-available-on-mobile.title')}}</p>

                    <button class="cta cta-slim cta-outline dark submit" @click="logout($router)">{{$t('navigation.logout')}}</button>
                </div>
            </div>

            <div class="modal-blur-background"></div>
        </div>
    </div>
</template>

<script>
const pb = require('progressbar.js');
let pace_line;

import cookies from 'js-cookie';
import { UAParser } from 'ua-parser-js'
import { bus } from '../main';
import { mapActions, mapGetters } from 'vuex';

import ConfirmEmailBanner from './Navigation/ConfirmEmailBanner.vue'
import ReferralBanner from './Navigation/ReferralBanner.vue'
import MainNavigation from './Navigation/MainNavigation.vue';
import SimpleNavigation from './Navigation/SimpleNavigation.vue';
import SideBar from './Navigation/SideBar.vue';
import Footer from './Navigation/Footer.vue';
import WorkerReferralModal from './Modals/WorkerReferralModal.vue';
import SupportModal from './Modals/SupportModal.vue';
import StatsDoNotMatchModal from './Modals/StatsDoNotMatchModal.vue';
import InstallOnMobileModal from './Modals/InstallOnMobileModal.vue';
import MobileNavigation from './Navigation/MobileNavigation.vue';
import PaymentMethodNeededModal from './Modals/PaymentMethodNeededModal.vue';

let interval;

export default {
    name: "Layout",
    components: {
        ConfirmEmailBanner,
        ReferralBanner,
        MainNavigation,
        SimpleNavigation,
        SideBar,
        Footer,
        WorkerReferralModal,
        SupportModal,
        InstallOnMobileModal,
        MobileNavigation,
        StatsDoNotMatchModal,
        PaymentMethodNeededModal
    },

    props: {
        classes: {
            type: Object,
            default() {
                return {};
            }
        },

        routeName: {
            type: String,
            default: "landing"
        },

        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        container: {
            type: Boolean,
            default: false,
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },

        in_modal: {
            type: Boolean,
            default: false,
        },

        pace: {
            activate: {
                type: Boolean,
                default: false
            },

            loaded: {
                type: Boolean,
                default: false
            },
        }
    },

    watch: {
        routeName: function() {
            const self = this;
            self.init_pace();
        },

        'pace': {
            deep: true,
            handler() {
                const self = this;
                if(self.pace && self.pace.activate && self.pace.loaded) {
                    if(pace_line && pace_line.svg) {
                        pace_line.set(1);
                        setTimeout(function() { 
                            try { pace_line.destroy(); } 
                            catch (error) { console.log(error); }
                        }, 100)
                    }
                }
            }
        },

        show_reload_for_new_version: function() {
            const self = this;
            if(self.show_reload_for_new_version) { 
                self.$utils._open_modal(); 
            }
            else { self.$utils._close_modal(); }
        },

        sidebar_state: function() {
            this.set_sidebar_state_class();
        }
    },

    data() {
        return {
            show_referral_modal: false,
            show_reload_for_new_version: false,
            meta: {initialized: false}
        }
    },

    computed: {
        ...mapGetters([
            'accountIsVerified',
            'is_authenticated',
            'current_user_id',
            'email',
            'name',
            'currentLanguage',
            'sidebar_state',
            'referral_modal_state',
            'support_modal_state',
            'payment_method_needed_modal_state',
            'stats_do_not_match_modal_state',
            'not_current_language',
            'show_install_instructions',
            'show_install_on_mobile_modal',
            'token',
            'breakpoint',
            'user_id',
            'website_email',
            'native_ios'
        ]),

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        },

        auth: function() {
            return this.routeName === 'login' || this.routeName === 'signup' || this.routeName === 'forgot-password' || this.routeName === 'reset-password' || this.routeName === 'validate-email-address'
        },

        show_confirm_email_banner: function() {
            return !this.accountIsVerified && this.is_dashboard_layout
        },

        is_dashboard_layout: function() {
            return this.sidebar;
        },
    },

    methods: {
        ...mapActions([
            'setCurrentLanguage',
            'setToken',
            'set_sidebar_state',
            'logout',
            'change_current_language',
            'set_ref_partner',
            'set_referred_code',
            'set_website_email',
            'fetch_ref_partner',
            'fetch_website_email',
            'fetch_referred_code',
            'set_ip',
            'fetch_ip',
            'set_container_margin',
            'set_show_install_instructions',
            'set_show_install_on_mobile_modal',
            'set_meta',
            'set_number_of_unread_messages',
            'set_messages_not_seen_by_sender',
            'set_breakpoint',
            'set_support_modal_state',

            'set_utm_source',
            'set_utm_medium',
            'set_utm_content',
            'set_utm_term',
            'set_utm_campaign',
            'set_native_ios',

            'fetch_utm_source',
            'fetch_utm_medium',
            'fetch_utm_content',
            'fetch_utm_term',
            'fetch_utm_campaign',
            'fetch_native_ios'
        ]),

        verify_account: function() {
            const self = this;
            
            if(!self.accountIsVerified && self.is_dashboard_layout) {
                self.$axios
                    .get('account/verify')
                    .then(response => {
                        if(response.status == 200) {
                            if(response.data.verified) {
                                document.body.classList.remove('active-confirm-email-banner');
                                self.setToken(response.data.token);
                            }
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        self.loaded = true;
                    });
            }
        },
        
        show_simple_navigation: function() {
            return this.navigation === 'simple' && this.$screen.width >= 768;
        },

        set_vh: function() {
            document.querySelector(':root').style.setProperty('--vh', window.innerHeight / 100 + 'px');
        },

        init_pace: function() {
            const self = this;

            if(self.pace && self.pace.activate) {
                pace_line = new pb.Line('#pace', { 
                    strokeWidth: self.device === 'mobile' ? 0.8 : 0.2,
                    easing: 'easeOut',
                    duration: 15000,
                    color: '#FFCE1A',
                });

                pace_line.animate(1);
            }
        },

        set_heading_container_margin: function() {
            const self = this;

            const margin = self.$screen.width > 1100 ? 18 : 12;
            const heading_element = document.getElementById('sticky-heading-container');
            
            if(heading_element) {
                const height = heading_element.clientHeight;
                const container_element = document.getElementById('with-sticky-header');
                
                if(container_element) {
                    container_element.style.marginTop = `${height + margin}px`;
                    self.set_container_margin(height + margin);
                }
            }
        },

        capture_query: function() {
            const self = this;

            if(self.$route.query) {
                if (self.$route.query.ref_partner) {
                    self.set_ref_partner({ref_partner: self.$route.query.ref_partner.trim(), override: true})
                }

                if(self.$route.query.ref) {
                    self.set_referred_code({ code: self.$route.query.ref.trim(), override: true })
                }

                if(self.$route.query.email) {
                    self.set_website_email({email: self.$route.query.email.trim(), override: true})
                }

                if(self.$route.query.nios && self.$route.query.nios.trim() === 't') {
                    self.set_native_ios({native_ios: true, override: true})
                }
                
                if(self.$route.query.utm_source) { self.set_utm_source({ utm_source: self.$xss(self.$route.query.utm_source.trim()), override: true }) }
                if(self.$route.query.utm_medium) { self.set_utm_medium({ utm_medium: self.$xss(self.$route.query.utm_medium.trim()), override: true }) }
                if(self.$route.query.utm_content) { self.set_utm_content({ utm_content: self.$xss(self.$route.query.utm_content.trim()), override: true }) }
                if(self.$route.query.utm_term) { self.set_utm_term({ utm_term: self.$xss(self.$route.query.utm_term.trim()), override: true }) }
                if(self.$route.query.utm_campaign) { self.set_utm_campaign({ utm_campaign: self.$xss(self.$route.query.utm_campaign.trim()), override: true }) }
            }
        },
        
        get_ip: function() {
            const self = this;
            
            if(process.env.VUE_APP_IS_CYPRESS) { return; }

            try {
                const instance = self.$axios.create({})

                instance.get(process.env.VUE_APP_IPFY_ENDPOINT)
                    .then(response => {
                        if(response.status == 200) {
                            self.set_ip(response.data.ip);
                        }else {
                            self.set_ip('cors');
                        }
                    })
                    .catch(() => {});
            } catch(err) { console.log(`LAYOUT: can't get ip: ${err}`); }
        },

        fetch_versioning: function() {
            const self = this;
            
            if(process.env.VUE_APP_IS_CYPRESS || process.env.VUE_APP_ENV === 'local') { return; }

            self.show_reload_for_new_version = false;
            try {
                const instance = self.$axios.create({})
                
                instance.get(process.env.VUE_APP_VERSIONING_ENDPOINT)
                    .then(response => {
                        if(response.status == 200) {
                            const version = response.data.version;
                            if(process.env.VUE_APP_VERSION !== version) {
                                self.show_reload_for_new_version = true;
                            }
                        }
                    })
                    .catch((e) => { console.log(`LAYOUT: error getting version, ${e}`)});
            } catch(err) { console.log(`LAYOUT: can't get version`); }
        },

        reload: function() {
            caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
            window.location.reload(true);
        },

        set_meta_classes: function() {
            const self = this;
            
            if(window && navigator) {
                const parser = new UAParser();
                const parsed_ua = parser.getResult();

                window.addEventListener('resize', () => {
                    self.set_vh(); 
                    self.set_heading_container_margin(); 
                    self.set_breakpoint_value();
                });

                window.addEventListener('load', () => { self.set_vh(); });

                let is_standalone_ios = window.navigator.standalone === true;
                if(!is_standalone_ios) { is_standalone_ios = window.clientInformation.standalone === true }
                
                //Extract values with fallbacks
                let browser_name = 'none';
                if(parsed_ua && parsed_ua.browser && parsed_ua.browser.name) { browser_name = parsed_ua.browser.name.trim().toLowerCase() }
                
                let os_name = 'none';
                if(parsed_ua && parsed_ua.os && parsed_ua.os.name) { os_name = parsed_ua.os.name.trim().toLowerCase() }

                let model = 'none';
                if(parsed_ua && parsed_ua.device && parsed_ua.device.model) { model = parsed_ua.device.model.trim().toLowerCase() }

                // Set device
                let device = 'none';

                if(model === 'iphone') { device = 'phone' }
                if(model === 'ipad') { device = 'tablet' }
                if(model === 'macintosh') { device = 'laptop' }
                if(os_name === 'Android') { device = 'phone' }    

                self.meta = {
                    is_ios: os_name === 'ios',
                    is_android: os_name === 'android',
                    is_mac_os: os_name === 'mac os',
                    
                    is_mobile: device === 'phone',
                    is_tablet: device === 'tablet',
                    is_laptop: device === 'laptop',
                    
                    is_safari: browser_name === 'safari' || browser_name === 'mobile safari',
                    is_duckduckgo: browser_name === 'duckduckgo',
                    is_chrome: browser_name === 'chrome',
                    is_firefox: browser_name === 'firefox',
                    is_edge: browser_name === 'edge',
                    is_brave: browser_name === 'brave',
                    is_opera: browser_name === 'opera' || browser_name === 'opera touch',
                    
                    is_standalone_ios,
                    is_standalone_android: window.matchMedia('(display-mode: standalone)').matches,

                    parsed_ua
                }

                self.set_meta(self.meta);

                document.body.classList.remove(...document.body.classList)
                Object.keys(self.meta).forEach(key => {
                    if(self.meta[key]) {
                        document.body.classList.add(key);
                    }
                })

                document.body.classList.add(`route-${self.is_dashboard_layout ? 'dashboard' : 'not-dashboard'}`);
            }
        },
        
        set_show_on_mobile: function() {
            const self = this;

            if(!self.meta.is_standalone_ios && !self.meta.is_standalone_android && (self.meta.is_ios || self.meta.is_mac_os || self.meta.is_android) && (self.meta.is_mobile || self.meta.is_tablet)) {
                const cookie = cookies.get('install_on_mobile_prompted');
                if(!cookie) {
                    self.set_show_install_on_mobile_modal(true);
                    cookies.set('install_on_mobile_prompted', true, { expires: 3, secure: true, sameSite: 'strict' });
                }
                
                self.set_show_install_instructions(true);
            }else { self.set_show_install_instructions(false); }
        },

        set_sidebar_state_class: function() {
            const self = this;

            document.body.classList.remove('menu-expanded');
            document.body.classList.remove('menu-collapsed');

            document.body.classList.add(`menu-${self.sidebar_state}`);
        },

        set_orientation: function() {
            document.body.classList.remove('orientright');
            document.body.classList.remove('orientleft');

            let orientation = 0;
            if(window.orientation) { orientation = window.orientation }
            if(screen && screen.orientation && screen.orientation.angle) { orientation = screen.orientation.angle }
            
            if (orientation == -90) { document.body.classList.add('orientright'); }
            if (orientation == 90) { document.body.classList.add('orientleft'); }
        },

        get_number_of_unread_messages: function() {
            const self = this;

            try {
                const instance = self.$axios.create({
                    baseURL: process.env.VUE_APP_CONVERSE_API_ENDPOINT,
                    headers: { 
                        'token': self.token,
                    }
                })
                    
                instance.get('unread-messages')
                    .then(response => {
                        if(response.status == 200) {
                            self.set_number_of_unread_messages(response.data.number_of_unread_messages);
                            self.set_messages_not_seen_by_sender(response.data.messages_not_seen_by_sender);
                        }
                    })
                    .catch(() => { console.log(`LAYOUT: error getting number of unread messages`)});
            } catch(err) { console.log(`LAYOUT: can't get number of unread messages`); }
        },

        init_sidebar_state: function() {
            const self = this;

            if(self.sidebar_state === '') {
                if(self.$screen.width  > 1024) { self.set_sidebar_state('expanded') }
                else { self.set_sidebar_state('collapsed') }
            }
        },

        set_breakpoint_value: function() {
            const self = this;

            if(self.$screen.width  > 768) { self.set_breakpoint('desktop') }
            else { self.set_breakpoint('mobile') }
        },

        segment_identify_user: function() {
            const self = this;

            if(window && window.analytics) {
                if(self.user_id && self.email) {
                    window.analytics.identify(self.user_id, {
                        email: self.email
                    });
                }else if (self.website_email) {
                    window.analytics.identify( {
                        email: self.website_email
                    });
                }

                self.segment_track_page();
            }
        },

        segment_track_page: function() {
            const self = this;
            window.analytics.page(self.routeName);
        }
    },

    mounted () {
        const self = this;
        self.setCurrentLanguage();
        self.init_pace();
        self.capture_query();
        self.get_ip();
        
        bus.$on('reset_heading_container_margin', () => {
            self.set_heading_container_margin();
        });     
        
        self.$nextTick(() => {
            self.set_meta_classes();
            self.set_heading_container_margin();
            self.fetch_ref_partner();
            self.fetch_website_email();
            self.fetch_referred_code();
            self.fetch_ip();
            self.verify_account();
            self.fetch_versioning();    
            self.set_show_on_mobile();
            self.init_sidebar_state();
            self.set_sidebar_state_class();
            self.set_breakpoint_value();

            self.fetch_utm_source();
            self.fetch_utm_medium();
            self.fetch_utm_content();
            self.fetch_utm_term();
            self.fetch_utm_campaign();
            self.fetch_native_ios();

            self.segment_identify_user();
                
            if(self.is_authenticated && self.is_dashboard_layout) {
                self.get_number_of_unread_messages();
            }

            interval = setInterval(function () { self.fetch_versioning() }, 10000);
        })

        document.body.classList.remove('active-confirm-email-banner');
        if(self.show_confirm_email_banner) {
            document.body.classList.add('active-confirm-email-banner');
        }

        window.addEventListener('orientationchange', function () {
            self.set_vh();
        }, true);
    },

    unmounted() {
        window.removeEventListener('load');
        window.removeEventListener('resize');
        window.removeEventListener('orientationchange');

        clearInterval(interval);
    },

    metaInfo: function(){
        const self = this;

        return {
            title: self.$t(`navigation.meta.title.${process.env.VUE_APP_ENV}`),
        }
    },
};
</script>