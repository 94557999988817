<template>
    <div :class="[`simple-navigation navbar simple-navigation-${routeName}`, { container }]">
        <div :class="['content']">
             <div class="logo-container">
                <router-link to="/" class="navbar-logo"> <img src="../../assets/images/likab_icon_circle.png" alt="LikaB logo" title="LikaB logo" loading="eager"></router-link>
            </div>   
            
            <div class="end">
                <a v-if="routeName === 'retirement-calculator'" class="link-item" href="https://www.likab.ca">{{$t('navigation.back-to-website')}}</a>
                <a v-if="routeName === 'get-started' || routeName === 'change-temporary-password'" id="navigation_help" class="cta cta-primary cta-slim mr-12" @click="set_support_modal_state(true)">{{$t('navigation.help')}}</a>
                <a v-if="routeName === 'get-started' || routeName === 'change-temporary-password'" id="navigation_logout" class="cta cta-outline cta-slim light" @click="logout($router)">{{$t('navigation.logout')}}</a>

                <a v-if="routeName === 'signup' || routeName === 'forgot-password' || routeName === 'reset-password'" id="login-simple-navigation" class="cta cta-outline light cta-slim" @click="$utils._navigate_to_name('login')">{{$t('signup.login')}}</a>                
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "SimpleNavigation",

    props: {
        routeName: {
            type: String,
            default: ''
        },

        container: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters([
            'currentLanguage',
            'not_current_language'
        ]),

        env: function() {
            return process.env.VUE_APP_ENV;
        }
    },

    methods: {
        ...mapActions([
            'change_current_language',
            'set_support_modal_state',
            'logout'
        ])
    }
};
</script>