<template>
    <div v-if="modal" class="modal support" :class="{ active: modal }">
        <div class="modal-card bottom">
            <div class="modal-title">
                <p class="title">{{$t('modal.support.title')}}</p>
                <font-awesome-icon @click="close()" :icon="['fa', 'square-xmark']" />
            </div>

            <div class="modal-body">
                <p class="subitle">{{$t('modal.support.subtitle')}}</p>
                
                <a class="cta cta-primary cta-slim" href="mailto:support@likab.ca">{{ $t('modal.support.choices.email') }}</a>
                <div v-if="show_logout" class="cta cta-outline error" @click="logout($router)">{{ $t('modal.support.choices.logout') }}</div>
                <!-- <div class="cta cta-outline dark" @click="open_beacon()">{{ $t('modal.support.choices.chat') }}</div> -->
                <FormError :data="validation.beacon"></FormError>
                
                <p class="app-version">© 2023 LikaB - {{ app_version }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormError from '../Utils/FormError.vue';

export default {
    name: 'SupportModal',

    components: {
        FormError
    },

    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        show: function(val) {
            const self = this;
            self.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { 
                self.validation.beacon.error = false;
                self.$utils._open_modal(); 
            }
            else { self.$utils._close_modal(); }
        }
    },
    
    data() {
        return {
            modal: false,
            loaded: false,

            validation: {
                beacon: {
                    error: false,
                    name: 'beacon',
                    key: 'errors.validation.emailInvalid'
                },
            },
        }
    },

    computed: {
        ...mapGetters([
            'onboardingProcessCompleted',
            'is_authenticated'
        ]),

        app_version: function() {
            return `v${process.env.VUE_APP_VERSION}`
        },

        show_logout: function() {
            return this.is_authenticated && !this.onboardingProcessCompleted && this.$screen.width < 768;
        },
    },
    
    methods: {
        ...mapActions([
            'set_support_modal_state',
            'logout'
        ]),

        close: function() {
            const self = this;

            // try {
            //     window.Beacon('close');
            //     window.Beacon('logout');
            //     window.Beacon('destroy');
            // } catch (e) {
            //     console.log(`error closing beacon: ${e}`)
            // }

            self.modal = false;
            self.validation.beacon.error = false;
            self.set_support_modal_state(false);
        },

        open_beacon: function() {
            const self = this;

            self.validation.beacon.false = true;

            try {
                window.Beacon('init', process.env.VUE_APP_HELP_SCOUT_BEACON_APP_ID);

                if(self.email) {
                    window.Beacon('identify', {
                        email: 'steve@aoki.com',
                        source: 'app'
                    })
                }else {
                    window.Beacon('identify', {
                        source: 'app'
                    })
                }

                window.Beacon('open');
                window.Beacon('navigate', '/ask/message/');
            } catch (e) {
                console.log(`error loading beacon: ${e}`)
                self.validation.beacon.error = true;
            }
        }
    }
}
</script>