<template>
    <div class="main-navigation navbar">
        <div class="content">
            <div class="logo-container">
                <font-awesome-icon v-if="show_close_icon" class="close" :icon="['fa', 'xmark']" @click="toggle_sidebar_state()" />
                <font-awesome-icon v-else class="bars" :icon="['fa', 'bars']" @click="toggle_sidebar_state()" />
                <router-link to="/" class="navbar-logo"> <img src="../../assets/images/likab_logo_min.png" alt="LikaB logo" title="LikaB logo" loading="eager"></router-link>
            </div>
            
            <div class="end">
                <div class="dropdown" @click="dropdownOpen = !dropdownOpen" :class="{open: dropdownOpen}">
                    <span class="dropdown-parent" ref="dropdown-parent">
                        <span v-if="isWorkProvider">{{name}}</span>
                        <span class="profile-type">{{$t(displayCurrentProfileType)}}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "MainNavigation",

    props: {
        isDashboard: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dropdownOpen: false,
            parentWidth: ''
        }
    },

    computed: {
        ...mapGetters([
            'name',
            'currentProfile',
            'currentProfileType',
            'scopes',
            'sidebar_state',
            'isWorkProvider'
        ]),

        displayCurrentProfileType: function() {
            const self = this;

            if(self.currentProfile === 'workProvider') {
                return `workProvider.type.${self.currentProfileType}`;
            } else if(self.currentProfile === 'admin') {
                return 'Admin';
            } else {
                return 'navigation.profile.types.worker';
            }
        },

        show_close_icon: function() {
            const self = this;
            return self.sidebar_state === 'expanded' && self.$screen.width <= 1024;
        },

        env: function() {
            return process.env.VUE_APP_ENV;
        }
    },

    methods: {
        ...mapActions([
            'logout',
            'setToken',
            'toggle_sidebar_state'
        ]),
        
        matchWidth: function() {
            const self = this;
            
            self.$nextTick(function () {
                const element = self.$refs['dropdown-parent'];
                if(element) {
                    self.parentWidth = `width: ${element.clientWidth}px;`
                }
            })
        }
    },

    mounted() {
        const self = this; 
        
        self.matchWidth();
    }
};
</script>