<template>
    <div :class="['side-bar', sidebar_state, {'work-provider': isWorkProvider, 'person': is_person}]">
        <div v-if="sidebar_state === 'expanded'" class="heading-container">
            <p class="title">{{ $t('sidebar.mobile.title') }}</p> 
        </div>

        <ul class="top"> 
            <li class="side-bar-item" 
                v-if="is_admin"
                id="sidebar-admin"
                :class="{active: is_active_route(['admin'])}"
                @click="$utils._navigate_to_name('amin', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'lock']" /><span class="text">{{$t('sidebar.admin')}}</span></li>
            
            <li class="side-bar-item" 
                v-if="isWorkProvider"
                id="sidebar-work-provider-offer-candidates"
                :class="{active: is_active_route(['work-provider-offer-candidates', 'work-provider-message'])}"
                @click="$utils._navigate_to_name('work-provider-offer-candidates', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'comments']" /><span class="text">{{$t('sidebar.work_provider_offer_candidates')}}</span></li>

            <li class="side-bar-item" 
                v-if="isWorkProvider"
                id="sidebar-work-provider-offers"
                :class="{active: is_active_route(['work-provider-offers', 'work-provider-offer', 'work-provider-match-detail', 'create-work-provider-offer'])}"
                @click="$utils._navigate_to_name('work-provider-offers', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'handshake-angle']" /><span class="text">{{$t(workProviderOffersLabel)}}</span></li>

            <li class="side-bar-item" 
                v-if="isWorkProvider"
                id="sidebar-locations"
                :class="{active: is_active_route(['locations', 'create-location', 'update-location'])}"
                @click="$utils._navigate_to_name_with_params('locations', { type: 'work-provider' }, side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'shop']" /><span class="text">{{$t('sidebar.workProvider.locations')}}</span></li>
            
            <li class="side-bar-item"   
                v-if="is_person && scopes.includes('worker')"
                id="sidebar-worker-matches"
                :class="['hide-mobile', {active: is_active_route(['worker-matches', 'worker-match-detail', 'worker-history', 'match-detail'])}]"
                @click="$utils._navigate_to_name('worker-matches', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'briefcase']" /><span class="text">{{$t('sidebar.worker')}} <span v-if="number_of_new_offers !== 0" class="new-offers">{{ number_of_new_offers }}</span></span></li>
            
            <li class="side-bar-item" 
                v-if="is_person && scopes.includes('worker')"
                id="sidebar-worker-preferences"
                :class="['hide-mobile', {active: is_active_route(['worker-preferences'])}]"
                @click="$utils._navigate_to_name('worker-preferences', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'clipboard']" /><span class="text">{{$t('sidebar.preferences')}}</span></li>
            
            <li class="side-bar-item" 
                v-if="is_person && scopes.includes('worker')"
                id="sidebar-worker-relations"
                :class="['hide-mobile', {active: is_active_route(['worker-relations'])}]"
                @click="$utils._navigate_to_name('worker-relations', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'users']" /><span class="text">{{$t('sidebar.worker-relations')}}</span></li>

            <li class="side-bar-item with-icon" 
                v-if="is_person && scopes.includes('worker')"
                id="sidebar-worker-messaging"
                :class="['hide-mobile', {active: is_active_route(['messaging', 'message'])}]"
                @click="$utils._navigate_to_name('messaging', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'comments']" /><span v-if="number_of_unread_messages !== 0" class="icon warning">{{ number_of_unread_messages }}</span><span class="text">{{$t('sidebar.messaging')}}</span></li>

            <li class="side-bar-item"
                v-if="is_person && scopes.includes('worker')"
                id="sidebar-worker-points"
                :class="[{active: is_active_route(['points'])}]"
                @click="$utils._navigate_to_name('points', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'sack-dollar']" /><span class="text">{{$t('sidebar.points')}}</span></li>
        </ul>

        <ul class="bottom">
            <li class="side-bar-item profile"
                v-if="isWorkProvider"
                id="sidebar-work-provider-payments"
                :class="{active: is_active_route(['payments'])}"
                @click="$utils._navigate_to_name('payments', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'wallet']" /><span class="text">{{$t('sidebar.payments')}}</span></li>

            <li v-if="!is_admin"
                class="side-bar-item profile" 
                id="sidebar-profile"
                :class="{active: is_active_route(['profile'])}"
                @click="$utils._navigate_to_name('profile', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'user']" /><span class="text">{{$t('sidebar.profile')}}</span></li>

            <li v-if="!is_admin"
                id="sidebar-settings"
                class="side-bar-item"
                :class="{active: is_active_route(['settings'])}"
                @click="$utils._navigate_to_name('settings', side_bar_full_screen())"><font-awesome-icon :icon="['fa', 'gear']" /><span class="text">{{$t('sidebar.settings')}}</span></li>
            
            <li v-if="!is_admin"
                id="sidebar-support"
                class="support" @click="set_support_modal_state(true)"><font-awesome-icon :icon="['fa', 'headset']" /><span class="text">{{$t('sidebar.mobile.contact')}}</span></li>
            
            <li class="language-switcher" id="sidebar-language" @click="change_current_language(currentLanguage)"><font-awesome-icon :icon="['fa', 'earth-americas']" /><span class="text">{{$t(notCurrentLanguage(currentLanguage))}}</span></li>
            
            <li id="navigation_logout" class="side-bar-item" @click="logout($router)"><font-awesome-icon :icon="['fa', 'right-from-bracket']" /><span class="text">{{$t('sidebar.logout')}}</span></li>
                
            <li v-if="show_install_instructions" id="see_instructions" class="side-bar-item" @click="set_show_install_on_mobile_modal(true)"><font-awesome-icon :icon="['fa', 'cloud-arrow-down']" /><span class="text">{{$t('navigation.install.again', { device: meta.is_mobile ? $t('navigation.install.devices.mobile') : $t('navigation.install.devices.tablet') })}}</span></li>

            <ReferralBanner v-if="!show_confirm_email_banner" :in_menu="true"  />  
            <ConfirmEmailBanner v-if="show_confirm_email_banner" :in_menu="true" />
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ReferralBanner from '../Navigation/ReferralBanner.vue'
import ConfirmEmailBanner from '../Navigation/ConfirmEmailBanner.vue'

export default {
    name: "SideBar",

    components: {
        ReferralBanner,
        ConfirmEmailBanner
    },

    data() {
        return {
            number_of_new_offers: 0,
        }
    },

    computed: {
        ...mapGetters([
            'accountIsVerified',
            'currentLanguage',
            'isWorkProvider',
            'is_person',
            'scopes',
            'currentProfileType',
            'sidebar_state',
            'age_group',
            'name',
            'is_admin',
            'show_install_instructions',
            'meta',
            'number_of_unread_messages'
        ]),

        workProviderOffersLabel: function() {
            return this.currentProfileType === 'paid' ? 'sidebar.workProviderOffers.business' : 'sidebar.workProviderOffers.volunteer';
        },

        show_confirm_email_banner: function() {
            return !this.accountIsVerified
        },
    },

    methods: {
        ...mapActions([
            'change_current_language',
            'toggle_sidebar_state',
            'logout',
            'set_show_install_on_mobile_modal',
            'set_support_modal_state'
        ]),

        notCurrentLanguage: function(currentLanguage) {
            return currentLanguage === 'fr' ? 'language.en.long' : 'language.fr.long';
        },

        is_active_route: function(names) {
           return names.includes(this.$route.name);
        },

        side_bar_full_screen: function(breakpoint) {
            const self = this;

            if(breakpoint === 'tablet') {
                return self.sidebar_state === 'expanded' && (self.$screen.width <= 1024 && self.$screen.width >= 769)
            }else {
                return self.sidebar_state === 'expanded' && (self.$screen.width <= 768)
            }
        },
    },
};
</script>