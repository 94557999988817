<template>
    <div v-if="show_banner" @click="set_referral_modal_state(true)" class="banner-referral">
        <p v-html="$t('profile.referral.worker.title')"></p>
        <p class="link">{{$t('profile.referral.worker.cta')}}</p>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    name: "ReferralBanner",
    
    props: {
        in_menu: { type: Boolean, default: false },
    },

    data() {
        return {
            loading: false,
        }
    },

    computed: {
        ...mapGetters([
            'referral_code'
        ]),

        show_banner: function() {
            const self = this;

            if(self.in_menu) { return true }
            const routes_to_show = ['worker-matches', 'settings', 'profile', 'points'];
            return self.referral_code && self.referral_code !== 'none' && self.referral_code[0] === 'T' && routes_to_show.includes(self.$route.name);
        }
    },
    
    methods: {
        ...mapActions([
            'set_referral_modal_state'
        ])
    }
};
</script>