<template>
    <div class="footer">
        <ul class="items">
            <li class="text">{{$t('navigation.footer.notice')}}</li>
            <li class="separator">|</li>
            <li class="text">{{$t('navigation.footer.copyright')}}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Footer"
};
</script>