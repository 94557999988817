<template>
    <div v-if="modal" class="modal referral-worker" :class="{ active: modal }">
        <div class="modal-card">
            <div class="modal-title">
                <p class="title">{{$t('profile.referral.worker.modal.title')}}</p>
                <font-awesome-icon @click="close()" :icon="['fa', 'square-xmark']" />
            </div>

            <div class="modal-body">
                <p class="desc" v-html="$t('profile.referral.worker.modal.description')"></p>
                <div class="link-container">
                    <p class="title">{{$t('profile.referral.worker.modal.link.title')}}</p>
                    <div class="link-wrapper">
                        <input :class="['input input-text link readonly']" :value="referral_link" type="text">
                        
                        <copy-to-clipboard :text="referral_link" @copy="copy_to_clipboard('link')">
                            <button class="copy-link">{{$t('profile.referral.worker.modal.code.click')}}</button>
                        </copy-to-clipboard>
                    </div>
                </div>
            </div>

            <div v-if="loaded">
                <div class="referrals">
                    <p class="title">{{ $t('profile.referral.worker.modal.referrals.title') }}</p>
                    <span class="number">{{ referrals.length }}</span>
                </div>
                
                <p class="disclaimer">{{ $t('profile.referral.worker.modal.referrals.disclaimer') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import CopyToClipboard from 'vue-copy-to-clipboard';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'WorkerReferralModal',

    components: {
        CopyToClipboard
    },

    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        show: function(val) {
            const self = this;
            self.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { 
                self.$utils._open_modal(); 
                self.get_referrals();
            }
            else { self.$utils._close_modal(); }
        }
    },
    
    data() {
        return {
            modal: false,
            loaded: false,
            referred_by: {},
            referrals: []
        }
    },

    computed: {
        ...mapGetters([
            'referral_code'
        ]),

        referral_link: function() {
            const self = this;
            return `${process.env.VUE_APP_URL}/signup?ref=${self.referral_code}`
        }
    },
    
    methods: {
        ...mapActions([
            'set_referral_modal_state'
        ]),

        get_referrals: function() {
            const self = this;
            self.$axios
                .get('/referrals')
                .then(response => {
                    if(response.status == 200) {
                        self.referrals = response.data.referrals;
                        self.referred_by = response.data.referred_by;

                        self.$nextTick(() => {
                            self.loaded = true;
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.$nextTick(() => {
                        self.loaded = true;
                    })
                });
        },

        copy_to_clipboard: function(type) {
            const self = this;
            self.$toasted.success(
                self.$t(`notifications.referral_${type}_copied`),
                { icon: 'circle-check' }
            );
        },

        close: function() {
            const self = this;

            self.modal = false;
            self.set_referral_modal_state(false)
            self.$emit('close');
        },
    }
}
</script>