<template>
    <div :class="['mobile-navigation']" v-if="!simple && !get_started">
        <!-- <div class="items simple" v-if="simple">
            <router-link :class="['retirement-calculator-item', { active_item: is_active_route(['retirement-calculator', 'retirement-calculator-embedded'])}]" :to="{name: 'retirement-calculator'}">
                <div class="item-container">
                    <font-awesome-icon :icon="['fa', 'calculator']" />
                    <span class="label">{{ $t('navigation.mobile.labels.calculator') }}</span>
                </div>
            </router-link>

            <div class="circle-container">
                <div class="circle">
                    <router-link to="/"><img src="../../assets/images/likab_icon_circle.png" alt="LikaB icon circle" title="LikaB icon circle" loading="eager" /></router-link>
                </div>
            </div>

            <span class="language-switcher" @click="change_current_language(currentLanguage)">
                <div class="item-container">
                    <font-awesome-icon :icon="['fa', 'earth-americas']" />
                    <span class="label">{{ $t(`navigation.mobile.labels.lang.${not_current_language}`) }}</span>
                </div>
            </span>
        </div>
        
        <div class="items get-started" v-else-if="get_started">
            <span :class="['logout']" @click="logout($router)">
                <div class="item-container">
                    <font-awesome-icon :icon="['fa', 'right-from-bracket']" />
                    <span class="label">{{ $t('navigation.mobile.labels.logout') }}</span>
                </div>
            </span>

            <div class="circle-container">
                <div class="circle">
                    <router-link to="/"><img src="../../assets/images/likab_icon_circle.png" alt="LikaB icon circle" title="LikaB icon circle" loading="eager" /></router-link>
                </div>
            </div>

            <span class="language-switcher" @click="change_current_language(currentLanguage)">
                <div class="item-container">
                    <font-awesome-icon :icon="['fa', 'earth-americas']" />
                    <span class="label">{{ $t(`navigation.mobile.labels.lang.${not_current_language}`) }}</span>
                </div>
            </span>
        </div> -->

        <div class="items">
            <router-link :class="['work-offers-item', { active_item: is_active_route(['worker-matches', 'worker-match-detail', 'worker-history', 'match-detail'])}]" :to="{ name: 'worker-matches' }">
                <div class="item-container"  @click="set_sidebar_state('collapsed')">
                    <font-awesome-icon :icon="['fa', 'briefcase']" />
                    <span class="label">{{ $t('navigation.mobile.labels.worker-matches') }}</span>
                </div>
            </router-link>

            <router-link :class="['work-preferences-item', { active_item: is_active_route(['worker-preferences'])}]" :to="{ name: 'worker-preferences' }">
                <div class="item-container"  @click="set_sidebar_state('collapsed')">
                    <font-awesome-icon :icon="['fa', 'clipboard']" />
                    <span class="label">{{ $t('navigation.mobile.labels.worker-preferences') }}</span>
                </div>
            </router-link>

            <router-link :class="['worker-relations-item', { active_item: is_active_route(['worker-relations'])}]" :to="{ name: 'worker-relations' }">
                <div class="item-container"  @click="set_sidebar_state('collapsed')">
                    <font-awesome-icon :icon="['fa', 'users']" />
                    <span class="label">{{ $t('navigation.mobile.labels.worker-relations') }}</span>
                </div>
            </router-link>

            <div class="circle-container">
                <div class="circle">
                    <router-link to="/"><img src="../../assets/images/likab_icon_circle.png" alt="LikaB icon circle" title="LikaB icon circle" loading="eager" /></router-link>
                </div>
            </div>

            <router-link :class="['messages-item', { active_item: is_active_route(['messaging', 'message'])}]" :to="{ name: 'messaging' }">
                <div class="item-container with-icon" @click="set_sidebar_state('collapsed')">
                    <font-awesome-icon :icon="['fa', 'comments']" />
                    <span class="label">{{ $t('navigation.mobile.labels.messaging') }}</span>
                    <span v-if="number_of_unread_messages !== 0" class="icon warning mobile responsive">{{ number_of_unread_messages }}</span>
                </div>
            </router-link>
            
            <span :class="['menu', { active_item: sidebar_state === 'expanded' }]" @click="toggle_sidebar_state(true)">
                <div class="item-container with-icon">
                    <font-awesome-icon :icon="['fa', 'bars']" />
                    <span class="label">{{ $t('navigation.mobile.labels.menu') }}</span>
                    <span v-if="!accountIsVerified" class="icon warning mobile responsive">!</span>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "MobileNavigation",

    props: {
        routeName: {
            type: String,
            default: ''
        },
    },

    computed: {
        ...mapGetters([
            'currentLanguage',
            'sidebar_state',
            'not_current_language',
            'token',
            'accountIsVerified',
            'number_of_unread_messages'
        ]),

        simple: function() {
            const self = this;
            const route_names = ['login', 'signup', 'retirement-calculator', 'forgot-password', 'reset-password', 'validate-email-address'];
            return route_names.includes(self.routeName) && !self.token;
        },

        get_started: function() {
            const self = this;
            return self.routeName === 'get-started' || self.routeName === 'welcome';
        },

        env: function() {
            return process.env.VUE_APP_ENV;
        },

        auth: function() {
            return this.routeName === 'login' || this.routeName === 'signup' || this.routeName === 'forgot-password' || this.routeName === 'reset-password'
        }
    },

    methods: {
        ...mapActions([
            'set_sidebar_state',
            'change_current_language',
            'toggle_sidebar_state',
            'logout'
        ]),

        is_active_route: function(names) {
            const self = this;
            if(self.sidebar_state === 'expanded') { return false; }
            return names.includes(this.$route.name);
        }
    }
};
</script>